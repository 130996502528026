import Footer from "./Home/Footer";
import HeroSection from "./Home/HeroSection";
import ScrollToTop from "./Home/ScrollToTop";
import translations from "../data/translations";
import { useLanguage } from "./LanguageProvider";

export default function CourseNutrition() {
  const { language } = useLanguage();
  const t = translations[language];
  return (
    <>
      <ScrollToTop />
      <HeroSection page="nutrition" classname="nutrition" title="Nutrition" />
      <section
        id="AboutMe"
        className="container my-4 about-section with-background"
      >
        <h1 className="text-center">Nutrition</h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box mb-3">
            <div className="row">
              <div className="col-md-8">
                <p>{t.nutrition}</p>
                <p>{t.nutrition1}</p>
                <p>
                {t.nutrition2}
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="../img/nut.jpg"
                  alt="About Me"
                  className=" rounded"
                  style={{ height: "280px", width: "100%", objectFit: "cover" }}
                />
              </div>
            </div>

            <h1 className="text-center my-3">
            {t.nutritionTitle}
            </h1>
            <div className="row">
              <div className="col-md-5">
                <img
                  src="../img/nut-down.jpg"
                  alt="About Me"
                  className=" rounded my-3"
                  style={{
                    height: "515px",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "55% 63%",
                  }}
                />
              </div>
              <div className="col-md-7">
                <p>
                {t.nutrition3}
                </p>
                <p>
                {t.nutrition4}
                </p>
                <p>
                {t.nutrition5}
                </p>
              </div>
            </div>

            <p>
            {t.nutrition6}
            </p>
            <h1 className="text-center">{t.nutritionTitle1}</h1>
            <div className="row">
              <div className="col-md-6">
                <p>
                {t.nutrition7}
                </p>
              </div>
              <div className="col-md-4">
                <img
                  src="../img/n.jpg"
                  alt="About Me"
                  className=" rounded text-center"
                  style={{
                    height: "225px",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "69%,50%",
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-4">
                <a
                  className="btn-github-about mt-2 text-center"
                  href="https://forms.gle/fNxKHwG1FkbMkyMq9"
                  style={{ textDecoration: "none" }}
                >
                 {t.booknow}
                </a>
              </div>
              <div className="col-md-3"></div>
            </div>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
