import ScrollToTop from "./Home/ScrollToTop";
import data from "../data/index.json";
import Footer from "./Home/Footer";
import HeroSection from "./Home/HeroSection";
import translations from "../data/translations";
import { useLanguage } from "./LanguageProvider";

export default function CourseSound() {
  const { language } = useLanguage();
  const t = translations[language];
  return (
    <>
      <ScrollToTop />
      <HeroSection page="yoga" classname="sound" title="Sound Healing" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center"></h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box mb-3">
            <p>{t.sound}</p>
            <h1 className="text-center">{t.soundTitle}</h1>
            <p>{t.sound1}</p>
            {/* <div className="row p-2">
              <div className="col-md-4">
                <p>
                  <ul>
                    <li>Deep relaxation and release of tension</li>
                    <li>Improves sleep</li>
                    <li>Purifies and harmonizes our emotions and feelings</li>
                    <li>Stimulates and strengthens the immune system</li>
                  </ul>
                </p>
              </div>
              <div className="col-md-5">
                <p>
                  <ul>
                    <li>
                      Reduction of stress and stimulation of blood circulation
                    </li>
                    <li>
                      Changes our old patterns of behavior, habits and ways of
                      thinking
                    </li>
                    <li>Connection with the higher self</li>
                    <li>Helps recover from trauma</li>
                    <li>Eliminates blockages and toxins</li>
                    <li>Reduce anger</li>
                  </ul>
                </p>
              </div>
            </div> */}
            <h1 className="text-center">{t.soundTitle1}</h1>
            <p>{t.sound2}</p>
            <p>{t.sound3}</p>
            <p>{t.sound4}</p>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-journey">
        <svg
          class="wave-journey"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="my-4" style={{ background: "#a9b9be" }}>
        <section id="AboutMe" className="container my-4 about-section">
          <h1 className="text-center">{t.soundTitle2}</h1>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 content-box">
              <p>{t.sound5}</p>
              <p>{t.sound6}</p>
            </div>
          </div>
          <h1 className="text-center">{t.soundTitle3}</h1>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 content-box">
              <b> 1. {t.soundTitle4}</b>
              <p>{t.sound7}</p>
              <b>2. {t.soundTitle5}</b>
              <p>{t.sound8}</p>
              <b>3. {t.soundTitle6}</b>
              <p>{t.sound9}</p>
              <b>4. {t.soundTitle7}</b>
              <p>{t.sound10}</p>
            </div>
          </div>
          <div className="row p-5"></div>
        </section>
      </div>
      <div className="wave-container-journey2">
        <svg
          className="wave-journey2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
          style={{ background: "#a9b9be" }}
        >
          <path
            fill="#f1ebeb"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">{t.soundTitle8}</h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box">
            <div className="container mt-2">
              <div className="row">
                <div className="col-md-5">
                  <img
                    src="../img/sound-healing.png"
                    className="card-img mb-2 rounded"
                    alt="Yoga Pose"
                    style={{
                      width: "100%",
                      height: "339px",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <div className="col-md-7">
                  <h1>{t.soundTitle9}</h1>
                  <p> {t.sound11}</p>
                  <a
                    className="btn-github-about mt-2"
                    href="https://forms.gle/fNxKHwG1FkbMkyMq9"
                    style={{ textDecoration: "none" }}
                  >
                    {t.booknow}
                  </a>
                </div>
                {/* <div className="col-md-5">
                  <img
                    src="../img/sound-healing-middle.jpg"
                    className="card-img mb-2"
                    alt="Yoga Pose"
                  />
                  <h1>7 chakra and Nadi balancing</h1>
                  <p>
                    {" "}
                    Balancing the inner world through deep vibration, alignment
                    and cleansing of Nadis (energy channels) and Chakras (energy
                    centers).
                  </p>
                  <a
                    className="btn-github-about mt-2"
                    href="https://www.instagram.com/yogabykora"
                    style={{ textDecoration: "none" }}
                  >
                    Book Now
                  </a>
                </div> */}
                {/* <div className="col-md-6">
                  <div
                    className="card text-white my-5 "
                    style={{
                      background: "#a9b9be",
                      borderRadius: "91px 0 91px 0",
                      borderColor: "#a9b9be",
                    }}
                  >
                    <img
                      src="../img/sound-healing.png"
                      className="card-img"
                      alt="Yoga Pose"
                      style={{
                        borderRadius: "91px 0 91px 0",
                        borderColor: "#a9b9be",
                      }}
                    />
                    <div className="blog_posts_image_content">
                      <span className="badge badge-primary">
                        Himalaya sound meditation
                      </span>
                      <h5 class="card-title">
                        Bath in the sounds and vibrations of Tibetan Singing
                        Bowls and enjoy a deep relaxing and meditative state of
                        being. Binaural Frequencies invite to wander between
                        dimensions and to explore your inner and outer world. A
                        form of sound meditation that is suitable for groups.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="card text-white my-5"
                    style={{
                      background: "#a9b9be",
                      borderRadius: "91px 0 91px 0",
                      borderColor: "#a9b9be",
                    }}
                  >
                    <img
                      src="../img/sound-healing-middle.jpg"
                      className="card-img"
                      alt="Yoga Pose"
                      style={{
                        background: "#a9b9be",
                        borderRadius: "91px 0 91px 0",
                        borderColor: "#a9b9be",
                      }}
                    />
                    <div className="blog_posts_image_content">
                      <span class="badge badge-primary">
                        7 chakra and Nadi balancing
                      </span>
                      <h5 class="card-title">
                        Balancing the inner world through deep vibration,
                        alignment and cleansing of Nadis (energy channels) and
                        Chakras (energy centers).
                      </h5>
                    </div>
                  </div>
                </div> */}
                {/* <div className="col-md-4">
                    <div
                      class="card text-white my-5 "
                      style={{
                        background: "#a9b9be",
                        borderRadius: "91px 0 91px 0",
                        borderColor: "#a9b9be",
                      }}
                    >
                      <img
                        src="../img/sound-healing.png"
                        class="card-img"
                        alt="Yoga Pose"
                        style={{
                          borderRadius: "91px 0 91px 0",
                          borderColor: "#a9b9be",
                        }}
                      />
                      <div class="blog_posts_image_content">
                        <span class="badge badge-primary">
                          WORK SHOP Sound healing Bowl
                        </span>
                        <h5 class="card-title">
                          1:30 hour <br />
                          GROUP
                        </h5>
                      </div>
                    </div>
                  </div> */}
              </div>
              <hr />
              <div className="row">
                <div className="col-md-7">
                  <h1>{t.soundTitle10}</h1>
                  <p> {t.sound12}</p>
                  <a
                    className="btn-github-about mt-2"
                    href="https://forms.gle/fNxKHwG1FkbMkyMq9"
                    style={{ textDecoration: "none" }}
                  >
                    {t.booknow}
                  </a>
                </div>
                <div className="col-md-5">
                  <img
                    src="../img/s-book.jpg"
                    className="card-img mb-2 rounded"
                    alt="Yoga Pose"
                    style={{
                      width: "100%",
                      height: "339px",
                      objectFit: "cover",
                      objectPosition: "61% 46%",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>

      <Footer />
    </>
  );
}
