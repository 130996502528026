import { CSSTransition } from "react-transition-group";
import HeroSection from "./HeroSection";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";
import {
  InstagramOutlined,
  MailOutlined,
  MobileOutlined,
  StarFilled,
  StarTwoTone,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function RetreatNepal() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <ScrollToTop />
      <HeroSection page="retreat-index" classname="retreat-index" title="" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">Yoga & Retreat</h1>

        <div className="row align-items-center">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-4" style={{ cursor: "pointer" }}>
              <div
                className="card text-white my-5 "
                style={{
                  background: "#a9b9be",
                  borderRadius: "91px 0 91px 0",
                  borderColor: "#a9b9be",
                }}
                onClick={() => {
                  navigate("/retreat-india");
                }}
              >
                <img
                  src="../img/retreat/taj.jpg"
                  className="card-img"
                  alt="Yoga Pose"
                  style={{
                    borderRadius: "91px 0 91px 0",
                    borderColor: "#a9b9be",
                  }}
                />
                <div className="blog_posts_image_content">
                  <span className="badge badge-primary">INDIA</span>
                  {/* <h5 class="card-title">
                    Yoga Effects on Brain Health: A Systematic Review of the
                    Current Literature
                  </h5> */}
                  <br />
                  <a
                    onClick={() => {
                      navigate("/retreat-india");
                    }}
                    className="stretched-link"
                    style={{ marginLeft: "8px" }}
                  >
                    <i class="fa-solid fa-arrow-right" aria-hidden="true"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4" style={{ cursor: "pointer" }}>
              <div
                className="card text-white my-5"
                style={{
                  background: "#a9b9be",
                  borderRadius: "91px 0 91px 0",
                  borderColor: "#a9b9be",
                }}
                onClick={() => {
                  navigate("/retreat-nepal");
                }}
              >
                <img
                  src="../img/retreat/nep.jpg"
                  className="card-img"
                  alt="Yoga Pose"
                  style={{
                    background: "#a9b9be",
                    borderRadius: "91px 0 91px 0",
                    borderColor: "#a9b9be",
                  }}
                />
                <div className="blog_posts_image_content">
                  <span class="badge badge-primary">NEPAL</span>
                  {/* <h5 class="card-title">
                    Yoga Effects on Brain Health: A Systematic Review of the
                    Current Literature
                  </h5> */}
                  <br />
                  <a
                    onClick={() => {
                      navigate("/retreat-nepal");
                    }}
                    className="stretched-link"
                    style={{ marginLeft: "8px" }}
                  >
                    <i class="fa-solid fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
