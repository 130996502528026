import { CSSTransition } from "react-transition-group";
import HeroSection from "./HeroSection";
import { Carousel } from "antd";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import { useEffect, useState } from "react";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";

const contentStyle = {
  height: "300px", // Set the desired height for the carousel
  overflow: "hidden", // Ensure no overflow issues
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#364d79",
};

const imgStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover", // Ensure the image covers the entire area
};

export default function Retreat() {
  const { language } = useLanguage();
  const t = translations[language];

  const retreat = [
    {
      url: "../img/retreat/re1.jpg",
    },
    {
      url: "../img/retreat/re2.jpg",
    },
    {
      url: "../img/retreat/re3.jpg",
    },
    {
      url: "../img/retreat/re4.jpg",
    },
    {
      url: "../img/retreat/re5.jpg",
    },
    {
      url: "../img/retreat/re6.jpg",
    },
    {
      url: "../img/retreat/re7.jpg",
    },
    {
      url: "../img/retreat/re8.jpg",
    },
  ];

  const leftCarousel = [
    {
      url: "../img/retreat/left-carousel/re1.jpeg",
    },
    {
      url: "../img/retreat/left-carousel/re2.jpg",
    },
    {
      url: "../img/retreat/left-carousel/re3.jpeg",
    },
    {
      url: "../img/retreat/left-carousel/re4.jpg",
    },
    {
      url: "../img/retreat/left-carousel/re5.jpg",
    },
    {
      url: "../img/retreat/left-carousel/re6.jpg",
    },
  ];

  const rightCarousel = [
    {
      url: "../img/retreat/right-carousel/re1.jpeg",
    },
    {
      url: "../img/retreat/right-carousel/re2.jpeg",
    },
    {
      url: "../img/retreat/right-carousel/re3.jpeg",
    },
    {
      url: "../img/retreat/right-carousel/re4.jpeg",
    },
    {
      url: "../img/retreat/right-carousel/re5.jpeg",
    },
    {
      url: "../img/retreat/right-carousel/re6.jpeg",
    },
  ];

  const [leftImages, setLeftImages] = useState([]);
  const [rightImages, setRightImages] = useState([]);

  useEffect(() => {
    setLeftImages(leftCarousel);
    setRightImages(rightCarousel);
  }, []);

  return (
    <>
      <ScrollToTop />
      <HeroSection page="retreat" classname="retreat" title="" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">
          {t.retreatTitle}
          <br />
          {t.retreatTitle1}
        </h1>
        <p className="text-center my-2">
          {t.retreatDate}
          <br />
          {/* {t.retreatDate1} */}
        </p>

        <p className="text-center mb-5">{t.retreat1} </p>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box">
            <p>{t.retreat2}</p>
            <p>{t.retreat3}</p>
            <p>{t.retreat4}</p>
            <p> {t.retreat5}</p>

            <p className="mb-5">
              {" "}
              {t.retreat6}
              <ul>
                <li className="p-2">{t.retreat7}</li>
                <li className="p-2">{t.retreat8}</li>
                <li className="p-2">{t.retreat9}</li>
                <li className="p-2">{t.retreat10}</li>
                <li className="p-2">{t.retreat11}</li>
              </ul>
            </p>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-retreat">
        <svg
          class="wave-retreat"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="my-4" style={{ background: "#a9b9be" }}>
        <div className="container mt-2">
          <div className="row">
            {retreat.map((item, index) => (
              <div className="col-md-3">
                <div
                  key={index}
                  className="card text-white my-5"
                  style={{
                    background: "#a9b9be",
                    borderRadius: "91px 0 91px 0",
                    borderColor: "#a9b9be",
                  }}
                >
                  <img
                    src={item.url}
                    className="card-img"
                    alt="Yoga Pose"
                    style={{
                      background: "#a9b9be",
                      borderRadius: "91px 0 91px 0",
                      borderColor: "#a9b9be",
                      height: "200px", // Adjust the height as needed
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="wave-container-portfolio">
        <svg
          className="wave-portfolio"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
          style={{ background: "#a9b9be" }}
        >
          <path
            fill="#f1ebeb"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">{t.program}</h1>
        <div className="row align-items-center">
          <div className="content-box mt-5">
            <b className="mb-5">
              <u>{t.programTitle}</u>
            </b>
            <p className="mt-2">{t.program1}</p>

            <p>{t.program2}</p>

            <p>{t.program3}</p>

            <b className="mb-5">
              <u>{t.programTitle1}</u>
            </b>
            <p className="mt-2">{t.program4}</p>

            <p>{t.program5}</p>

            <p>{t.program6}</p>
            <p>{t.program7}</p>
            <p>{t.program8}</p>
            <p>{t.program9}</p>
            <p>{t.program10}</p>
            <p>{t.program11}</p>

            <b className="mb-5">
              <u>{t.programTitle2}</u>
            </b>
            <p className="mt-2">{t.program12}</p>

            <p>{t.program13}</p>

            <p>{t.program14}</p>
            <p>{t.program15}</p>
            <p>{t.program16}</p>
            <p>{t.program17}</p>
            <p>{t.program18}</p>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-retreat1">
        <svg
          class="wave-retreat1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div
        className="my-4"
        style={{ background: "#a9b9be", marginTop: "34px" }}
      >
        <div className="container mt-2">
          <div className="row" style={{ marginTop: "39px" }}>
            <div className="col-md-4">
              <Carousel autoplay>
                {leftImages.map((item, index) => (
                  <div key={index}>
                    <h3 style={contentStyle}>
                      <img src={item.url} loading="lazy" style={imgStyle} />
                    </h3>
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="col-md-4">
              <h1 className="text-center">{t.location}</h1>
              <p>{t.locationContent}</p>
            </div>
            <div className="col-md-4">
              <Carousel autoplay>
                {rightImages.map((item, index) => (
                  <div key={index}>
                    <h3 style={contentStyle}>
                      <img src={item.url} loading="lazy" style={imgStyle} />
                    </h3>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="row p-5"></div>
        </div>
      </div>
      <div class="wave-container-retreat2">
        <svg
          class="wave-retreat2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#f1ebeb"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">{t.pricing}</h1>
        <div className="row align-items-center">
          <div className="content-box mt-5">
            <b className="mb-5">
              <u>{t.pricingTitle}</u>
            </b>
            <p className="mt-2">{t.pricing1}</p>

            <p>{t.pricing2}</p>

            <b className="mb-5">
              <u>{t.pricingTitle3}</u>
            </b>
            <p className="mt-2">{t.pricing3}</p>

            <p>{t.pricing4}</p>

            <p>{t.pricing5}</p>
            <p>{t.pricing6}</p>
            <p>
              <b>{t.pricingNote}</b>
            </p>

            <b className="mb-5">
              <u>{t.pricingTitle4}</u>
            </b>
            <p className="mt-2">{t.pricing7}</p>
            <p>{t.pricing8}</p>
            <p>{t.pricing9}</p>
          </div>
        </div>
        <hr />
        <h1 className="text-center">{t.terms}</h1>
        <div className="row align-items-center">
          <div className="content-box mt-5">
            <b className="mb-5">
              <u>{t.terms1}:</u>
            </b>
            <p className="mt-2">{t.term2}</p>

            <p>{t.term3}</p>

            <p>{t.term4}</p>

            <p>{t.term5}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <a
              className="btn-github-about mt-2 text-center"
              href="https://forms.gle/fNxKHwG1FkbMkyMq9"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              {t.contact}
            </a>
          </div>
          <div className="col-md-4">
            <a
              className="btn-github-about mt-2 text-center"
              href="https://www.bookyogaretreats.com/share/BKTychY0yw/yogabykora"
              style={{ textDecoration: "none" }}
              target="_blank"
            >
              {t.booknow}
            </a>
          </div>
          <div className="col-md-2"></div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
