import { useState, useEffect, useContext } from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";

function Navbar() {
  const [navActive, setNavActive] = useState(false);
  const [dropdownActive, setDropdownActive] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const [languageDropdownActive, setLanguageDropdownActive] = useState(false);

  const toggleNav = () => {
    setNavActive(!navActive);
  };

  const closeMenu = () => {
    setNavActive(false);
  };

  const toggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const closeDropdown = () => {
    setDropdownActive(false);
  };

  const toggleLanguageDropdown = () => {
    setLanguageDropdownActive(!languageDropdownActive);
  };

  const closeLanguageDropdown = () => {
    setLanguageDropdownActive(false);
  };

  const navigate = useNavigate();

  const navigateToMyJourney = () => {
    closeMenu();
    closeDropdown();
    navigate("/my-journey");
  };

  const navigateToRetreat = () => {
    closeMenu();
    closeDropdown();
    navigate("/yoga-retreat");
  };

  const navigateToContact = () => {
    closeMenu();
    closeDropdown();
    // navigate("/contact");
    navigate("/", { scroll: true, offset: 5000 });
  };

  const navigateToHome = () => {
    closeMenu();
    closeDropdown();
    navigate("/");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1200) {
        closeMenu();
        closeDropdown();
        closeLanguageDropdown();
      }
    };

    const handleClickOutside = (event) => {
      if (
        event.target.closest(".navbar--dropdown") === null &&
        dropdownActive
      ) {
        closeDropdown();
      }
      if (
        event.target.closest(".navbar--language-dropdown") === null &&
        languageDropdownActive
      ) {
        closeLanguageDropdown();
      }
    };

    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownActive, languageDropdownActive]);

  const t = translations[language];

  return (
    // <nav
    //   className={`navbar custom-navbar ${navActive ? "active" : ""}`}
    //   style={{
    //     display: "flex",
    //     justifyContent: "space-between",
    //     alignItems: "center",
    //     padding: "15px 20px",
    //     position: "fixed",
    //   }}
    // >
    //   <div>
    //     <img
    //       className="logo"
    //       onClick={navigateToHome}
    //       // src="/img/Heliotrope.svg"
    //       src="/img/lo1.png"
    //       alt="Logoipsum"
    //     />
    //   </div>
    //   <a
    //     className={`nav__hamburger ${navActive ? "active" : ""}`}
    //     onClick={toggleNav}
    //   >
    //     <span className="nav__hamburger__line"></span>
    //     <span className="nav__hamburger__line"></span>
    //     <span className="nav__hamburger__line"></span>
    //   </a>
    //   <div className={`navbar--items ${navActive ? "active" : ""}`}>
    //     <ul>
    //       <li>
    //         <Link
    //           onClick={navigateToHome}
    //           spy={true}
    //           smooth={true}
    //           offset={-70}
    //           duration={500}
    //           to="heroSection"
    //           className="navbar--content"
    //         >
    //           Home
    //         </Link>
    //       </li>
    //       <li>
    //         <Link
    //           onClick={navigateToMyJourney}
    //           activeClass="navbar--active-content"
    //           spy={true}
    //           smooth={true}
    //           duration={500}
    //           to="MyJourney"
    //           className="navbar--content"
    //         >
    //           About
    //         </Link>
    //       </li>
    //       <li className="nav-item navbar--dropdown">
    //         <Link
    //           onClick={toggleDropdown}
    //           spy={true}
    //           smooth={true}
    //           duration={500}
    //           to="Courses"
    //           className="navbar--content"
    //         >
    //           Yoga & Wellness
    //         </Link>
    //         {dropdownActive && (
    //           <div className="dropdown-menu">
    //             <a className="dropdown-item" href="/courses/yoga">
    //               Traditional Hatha Yoga
    //             </a>
    //             <a className="dropdown-item" href="/courses/sound-healing">
    //               Sound Healing Bowl
    //             </a>
    //             <a className="dropdown-item" href="/courses/nutrition">
    //               Nutrition
    //             </a>
    //             <a className="dropdown-item" href="/courses/massage">
    //               Massage
    //             </a>

    //           </div>
    //         )}
    //       </li>
    //       <li>
    //         <Link
    //           onClick={navigateToRetreat}
    //           spy={true}
    //           smooth={true}
    //           duration={500}
    //           to="Retreat"
    //           className="navbar--content"
    //         >
    //           Retreat
    //         </Link>
    //       </li>
    //       <li>
    //         <Link
    //           onClick={navigateToContact}
    //           spy={true}
    //           smooth={true}
    //           offset={-70}
    //           duration={500}
    //           to="footer"
    //           className="navbar--content"
    //         >
    //           Contact
    //         </Link>
    //       </li>
    //     </ul>
    //   </div>
    // </nav>
    <nav
      className={`navbar custom-navbar ${navActive ? "active" : ""}`}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px 20px",
        position: "fixed",
      }}
    >
      <div>
        <img
          className="logo"
          onClick={navigateToHome}
          src="/img/kora.png"
          alt="Logoipsum"
        />
      </div>
      <a
        className={`nav__hamburger ${navActive ? "active" : ""}`}
        onClick={toggleNav}
      >
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
        <span className="nav__hamburger__line"></span>
      </a>
      <div className={`navbar--items ${navActive ? "active" : ""}`}>
        <ul>
          <li>
            <Link
              onClick={navigateToHome}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="heroSection"
              className="navbar--content"
            >
              {t.home}
            </Link>
          </li>
          <li>
            <Link
              onClick={navigateToMyJourney}
              activeClass="navbar--active-content"
              spy={true}
              smooth={true}
              duration={500}
              to="MyJourney"
              className="navbar--content"
            >
              {t.about}
            </Link>
          </li>
          <li className="nav-item navbar--dropdown">
            <Link
              onClick={toggleDropdown}
              spy={true}
              smooth={true}
              duration={500}
              to="Courses"
              className="navbar--content"
            >
              {t.yogaWellness}
            </Link>
            {dropdownActive && (
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    closeMenu();
                    closeDropdown(), navigate("/courses/yoga");
                  }}
                >
                  {t.traditionalYoga}
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    closeMenu();
                    closeDropdown(), navigate("/courses/sound-healing");
                  }}
                >
                  {t.soundHealing}
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    closeMenu();
                    closeDropdown(), navigate("/courses/nutrition");
                  }}
                >
                  {t.nutritionMenu}
                </a>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    closeMenu();
                    closeDropdown(), navigate("/courses/massage");
                  }}
                >
                  {t.massage}
                </a>
              </div>
            )}
          </li>
          <li>
            <Link
              onClick={navigateToRetreat}
              spy={true}
              smooth={true}
              duration={500}
              to="Retreat"
              className="navbar--content"
            >
              {t.retreat}
            </Link>
          </li>
          <li>
            <Link
              onClick={navigateToContact}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              to="footer"
              className="navbar--content"
            >
              {t.contact}
            </Link>
          </li>
          <li className="nav-item navbar--dropdown">
            <Link
              onClick={toggleLanguageDropdown}
              spy={true}
              smooth={true}
              duration={500}
              to="Courses"
              className="navbar--content"
            >
              {language === "en" ? "English" : "Français"}
            </Link>
            {languageDropdownActive && (
              <div className="dropdown-menu">
                <a
                  className="dropdown-item"
                  onClick={() => {
                    toggleLanguage();
                    closeLanguageDropdown();
                  }}
                >
                  {language === "en" ? "Français" : "English"}
                </a>
              </div>
            )}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
