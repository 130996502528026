import ScrollToTop from "./Home/ScrollToTop";
import Footer from "./Home/Footer";
import HeroSection from "./Home/HeroSection";
import translations from "../data/translations";
import { useLanguage } from "./LanguageProvider";

export default function CourseMassage() {
  const { language } = useLanguage();
  const t = translations[language];
  return (
    <>
      <ScrollToTop />
      <HeroSection page="yoga" classname="massage" title="Massage" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">{t.massageTitle}</h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box mb-3">
            <div className="row">
              <div className="col-md-8">
                <p className="p-3">{t.massage1}</p>
              </div>
              <div className="col-md-4">
                <img
                  src="../img/m.jpg"
                  alt="About Me"
                  className=" rounded text-center"
                  style={{
                    height: "225px",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "69%,50%",
                  }}
                />
              </div>
            </div>
            <h1 className="text-center">{t.massageTitle2}</h1>
            <p className="p-3">{t.massage3}</p>
            <h1 className="text-center">{t.massageTtile3}</h1>
            <p className="p-3">
              <ul>
                <li>{t.massage4}</li>
                <li>{t.massage5}</li>
                <li>{t.massage6}</li>
                <li>{t.massage7}</li>
              </ul>
            </p>
          </div>
          <div className="row">
            <div className="col-md-5"></div>
            <div className="col-md-4">
              <a
                className="btn-github-about mt-2 text-center"
                href="https://forms.gle/fNxKHwG1FkbMkyMq9"
                style={{ textDecoration: "none" }}
              >
                {t.booknow}
              </a>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
