import React from "react";
import { CSSTransition } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import HeroSection from "./HeroSection";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";

export default function AboutMe() {
  const navigate = useNavigate();
  const { language } = useLanguage();
  const t = translations[language];

  return (
    <>
      <h1 className="text-center" style={{ marginTop: "100px" }}>
        {t.aboutTitle}
      </h1>
      <section id="AboutMe" className="container my-4 about-section">
        <div className="row align-items-center">
          {/* Left content */}
          <CSSTransition
            in={true} // This controls whether the transition is active
            timeout={20000000} // Duration of the animation in milliseconds
            classNames="fade" // Name of the CSS class for transition
            unmountOnExit // Remove the element from the DOM when the transition exits
          >
            <div className="col-12 col-md-6 content-box">
              <h1> {t.namaste}</h1>
              <p>
              {t.aboutPar1}
              </p>
              <p>
              {t.aboutPar2}
              </p>
              <p> {t.aboutPar3}</p>
              <p>
              {t.aboutPar4}
              </p>
              <p className="signature"> {t.aboutPar5}</p>
              <p className="signature"> {t.aboutPar6}</p>
            </div>
          </CSSTransition>

          {/* Right content */}
          <CSSTransition
            in={true}
            timeout={2000}
            classNames="fade"
            unmountOnExit
          >
            <>
              <div className="col-12 col-md-6">
                <div className="col-md-12">
                  <img
                    src="./img/yoga3.png"
                    alt="About Me"
                    className="img-fluid rounded"
                  />
                </div>
                <div className="col-md-12">
                  <button
                    className="btn-github-about mt-2"
                    onClick={() => navigate("/my-journey")}
                  >
                    {t.knowMore}
                  </button>
                </div>
              </div>
            </>
          </CSSTransition>
        </div>
      </section>
      {/* <HeroSection title="The goal is to be here and now" page="about-section" classname="about-section" /> */}
      {/* <div className="col-md-12 p-3">
        <div className="image-container">
          <img
            src="./img/ho.jpg"
            alt="About Me"
            className="rounded fixed-img"
          />
          <div className="quote">The goal is to be here and now</div>
        </div>
      </div> */}
      <div class="wave-container-about">
        <svg
          class="wave-about"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
    </>
  );
}
