import { Carousel } from "antd";
import data from "../../data/index.json";
import Footer from "./Footer";
import HeroSection from "./HeroSection";
import ScrollToTop from "./ScrollToTop";
import ScrollableImage from "../ScrollableImage";
import translations from "../../data/translations"; // Update the import path as needed
import { useLanguage } from "../LanguageProvider";

const contentStyle = {
  height: "300px", // Set the desired height for the carousel
  overflow: "hidden", // Ensure no overflow issues
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background: "#364d79",
};

const imgStyle = {
  width: "100%",
  height: "100%",
  objectFit: "cover", // Ensure the image covers the entire area
};
export default function MyJourney() {
  const { language } = useLanguage();
  const t = translations[language];
  const leftCarousel = [
    {
      url: "../img/bali1.jpg",
    },
    {
      url: "../img/bali3.jpg",
    },
    {
      url: "../img/bali2.jpg",
    },
  ];

  const Rishikesh = [
    {
      url: "./img/ri1.jpg",
    },
    {
      url: "./img/rishikesh2.jpg",
    },
    {
      url: "./img/ri2.jpg",
    },
  ];

  const vipasana = [
    {
      url: "./img/vipasana1.jpg",
    },
    {
      url: "./img/vipasana3.jpg",
    },
    {
      url: "./img/vipasana2.jpg",
    },
  ];

  const varanasi = [
    {
      url: "./img/varanasi2.jpg",
    },
    {
      url: "./img/varanasi1.jpg",
    },
    {
      url: "./img/varanasi3.jpg",
    },
  ];

  const vr = [
    {
      url: "./img/vr1.jpg",
    },
    {
      url: "./img/vr2.jpg",
    },
    {
      url: "./img/vr3.jpg",
    },
    {
      url: "./img/vr4.jpg",
    },
    {
      url: "./img/vr5.jpg",
    },
    {
      url: "./img/vr6.jpg",
    },
    {
      url: "./img/vr7.jpg",
    },
    {
      url: "./img/vr8.jpg",
    },
  ];

  const nepal = [
    {
      url: "./img/npp1.jpg",
    },
    {
      url: "./img/npp2.jpg",
    },
    {
      url: "./img/npp3.jpg",
    },
  ];
  return (
    <>
      <ScrollToTop />
      <HeroSection page="journey" classname="journey" title={t.journey} />

      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">
          {t.journeyTitle}
          <br />
          {t.journeyTitle1}
        </h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box">
            <div className="p-2"></div>
            <p>{t.journey1}</p>
            <ScrollableImage images={leftCarousel} />
            <p>{t.journey2}</p>
            <div className="courses--quote">
              <p>{t.journeyQuote}</p>
            </div>
            <p>{t.journey3}</p>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <div class="wave-container-journey">
        <svg
          class="wave-journey"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
        >
          <path
            fill="#a9b9be"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="my-4" style={{ background: "#a9b9be" }}>
        <section id="AboutMe" className="container my-4 about-section">
          <h1 className="text-center">Rishikesh</h1>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 content-box">
              <div className="p-2"></div>
              <p>{t.rishikesh}</p>
              <ScrollableImage images={Rishikesh} />
              <div className="courses--quote">
              <p>{t.rishikeshQuote}</p>
            </div>
              <hr />
              <h1 className="text-center">Vipassana</h1>
              <p>{t.vipasana}</p>
              <ScrollableImage images={vipasana} />
            </div>
          </div>
          <div className="row p-5"></div>
        </section>
      </div>
      <div className="wave-container-journey2">
        <svg
          className="wave-journey2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 310"
          style={{ background: "#a9b9be" }}
        >
          <path
            fill="#f1ebeb"
            fill-opacity="1"
            d="M0,160L80,181.3C160,203,320,245,480,240C640,235,800,181,960,176C1120,171,1280,213,1360,234.7L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
          ></path>
        </svg>
      </div>
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center">Varanasi</h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box">
            <div className="p-2"></div>
            <p>{t.varanasi}</p>
            <ScrollableImage images={varanasi} />
            <div className="courses--quote">
              <p>{t.varanasiQuote}</p>
            </div>
            <p>{t.varanasi1}</p>
            <hr />
            <h1 className="text-center">Nepal</h1>
            <p>{t.nepal}</p>
            <ScrollableImage images={nepal} />
            <p>{t.nepal1}</p>
            <hr className="my-2" />
            <h1 className="text-center my-4">{t.todayHereNow}</h1>

            <p>{t.todayHereNow1}</p>
            <img
              src="../img/ho.jpg"
              alt="About Me"
              className=" rounded my-3"
              style={{
                height: "332px",
                width: "100%",
                objectFit: "cover",
                objectPosition: "36% 39%",
              }}
            />
            <p>{t.todayHereNow2}</p>
            <p className="text-center">{t.todayHereNow3}</p>
            <p className="text-center">{t.todayHereNow4}</p>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
