import { useNavigate } from "react-router-dom";
import ScrollToTop from "./Home/ScrollToTop";
import Footer from "./Home/Footer";
import HeroSection from "./Home/HeroSection";
import translations from "../data/translations";
import { useLanguage } from "./LanguageProvider";

export default function CourseYoga() {
  const { language } = useLanguage();
  const t = translations[language];
  return (
    <>
      <ScrollToTop />
      <HeroSection page="yoga" classname="yoga" title="Hatha Yoga" />
      <section id="AboutMe" className="container my-4 about-section">
        <h1 className="text-center"></h1>
        <div className="row align-items-center">
          <div className="col-12 col-md-12 content-box mb-3">
            <p className="p-2">{t.yoga}</p>

            <div className="row">
              <div className="col-md-6">
                <p>
                  <ul>
                    <li>{t.yoga1}</li>
                    <li>{t.yoga2}</li>
                    <li>{t.yoga3}</li>
                    <li>{t.yoga4}</li>
                  </ul>
                </p>
                <p>
                  <b>{t.yoga5}</b>
                </p>
                <p>
                  <ul>
                    <li>{t.yoga6}</li>
                    <li>{t.yoga7}</li>
                    <li>{t.yoga8}</li>
                    <li>{t.yoga9}</li>
                    <li>{t.yoga10}</li>
                    <li>{t.yoga11}</li>
                    <li>{t.yoga12}</li>
                  </ul>
                </p>
              </div>
              <div className="col-md-6 text-center">
                <img
                  src="../img/coyoga.jpg"
                  alt="About Me"
                  width="60%"
                  height={400}
                  className=" rounded"
                />
              </div>
            </div>
            <div className="courses--quote">
              <p>{`"${t.yogaQuote}"`}</p>
            </div>
            <hr />
            <h1 className="text-center">{t.yogaTitle}</h1>
            <p>{t.yoga13}</p>
            <p>{t.yoga14}</p>
            <p>{t.yoga15}</p>
            <p>{t.yoga16}</p>
            <hr />
            <h1 className="text-center">{t.yogaTitle1}</h1>
            <p>{t.yoga16}</p>
            <p>{t.yoga17}</p>
            <p>{t.yoga18}</p>
            <p>{t.yoga19}</p>
            <p>{t.yoga20}</p>
            <p>{t.yoga21}</p>
            <p>{t.yoga22}</p>
          </div>
          <div className="row">
            <div className="col-md-5"></div>
            <div className="col-md-4">
              <a
                className="btn-github-about mt-2 text-center"
                href="https://forms.gle/fNxKHwG1FkbMkyMq9"
                style={{ textDecoration: "none" }}
              >
                {t.booknow}
              </a>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div className="row p-5"></div>
      </section>
      <Footer />
    </>
  );
}
